import React from "react"
import Title from "./Title"
import Blog from "./Blog"
import { Link } from "gatsby"
export const Blogs = ( {blogs, title, showLink} ) => {
  return (
    <section className='section blogs'>
      <Title title={title} className='' />
      <div className='section-center blogs-center'>
        {
          blogs.map((blog)=>{
            return (
            <Blog
            key={blog.frontmatter.title}
            id={blog.frontmatter.id}
            title={blog.frontmatter.title}
            image={blog.frontmatter.image}
            date={blog.frontmatter.date}
            category={blog.frontmatter.category}
            slug={blog.frontmatter.slug}
            description={blog.frontmatter.description} />
            )
          })
        }
      </div>
      {showLink && <Link to='/news/' className='btn center-btn'>view all news</Link>}
    </section>
  )
}
export default Blogs
