import React from "react"
import Title from "./Title"
import Project from "./Project"
import { Link } from "gatsby"
const Projects = ({projects, title, showLink}) => {
  
  return (
    <section className='section projects'>
      <Title title={title} />
      <div className='section-center projects-center'>
        {projects.map((project, index)=>{
          return (
            <Project 
              key={index}
              index={project.frontmatter.id}
              description={project.frontmatter.description}
              title={project.frontmatter.title}
              github={project.frontmatter.github}
              stack={project.frontmatter.stack}
              url={project.frontmatter.url}
              image={project.frontmatter.image}
              />
          )
        })}
      </div>
      {
        showLink && <Link to='/sponsors/' className='btn center-btn'>view all sponsors</Link>
      }
    </section>
  )
}

export default Projects
