import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Projects from "../components/Projects"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"
export default ({data}) => {
  const {
    projects: { nodes: projects },
    blogs: { nodes: blogs }
  } = data
  return (
  <Layout>
    <SEO title='Home' description='Lancaster Digital - Digital News in Lancaster' />
    <Hero/>
    <Services />
    <Blogs blogs={blogs} title='latest news' showLink />
    <Projects projects={projects} title='featured sponsors' showLink />
  </Layout>
  )
}
export const query = graphql`
  {
    projects:allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(projects)/"}}, sort: {fields: frontmatter___id, order: ASC}, limit: 3,) {
      nodes {
        frontmatter {
          github
          id
          description
          title
          url
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          stack {
            title
          }
        }
      }
    }
    blogs:allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(posts)/"}}, sort: {order: DESC, fields: frontmatter___date}, limit: 3,) {
      nodes {
        frontmatter {
          title
          slug
          date(formatString: "MMMM Do YYYY")
          category
          description
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        rawMarkdownBody
      }
    }
  }
`
