import React from "react"
// import PropTypes from "prop-types"
import Image from "gatsby-image"
import { FaShareSquare } from "react-icons/fa"
const Project = ({description, title, github, stack, url, image, index}) => {
  return (
    <article className='project'>
      <Image fluid={image.childImageSharp.fluid} className='project-img'/>
      <div className='project-info'>
        <span className='project-number'>0{index}.</span>
        <h3>{title}</h3>
        <p className='project-description'>
          {description}
        </p>
        <div className='project-stack'>
          {
            stack.map((item, index)=>{
              
              return <span key={index}>{item.title}</span>
            })
          }
        </div>
        <div className='project-links'>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <FaShareSquare className='project-icon' />
          </a>
        </div>
      </div>
    </article>
  )
}

// Project.propTypes = {}

export default Project
