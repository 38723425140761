import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"
const query = graphql`
  {
    file(relativePath: {eq: "hero-img.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Hero = () => {
  const {file: {
    childImageSharp: { fluid },
  },
} = useStaticQuery(query)

  return (
    <header className='hero'>
      <div className='section-center hero-center'>
        <article className='hero-info'>
          <div>
            <div className='underline'></div>
            <h1>Bringing Lancaster together online</h1>
            <h4>the latest news, events, and jobs from Lancaster's<br />Burgeoning digital sector</h4>
            <Link to='/newsletter/' className='btn'>Newsletter Signup</Link>
            <SocialLinks />
          </div>
        </article>
        
        <div className="rose hero-img">
          <svg className="rose-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 220">
            <path className="st0" d="M87.74 206.19c-27.78-10.62-71.22-75.71-41.72-113.27s93.08-59.29 123.69-26.1 61.46 82.23 21.11 114.8-75.3 35.19-103.08 24.57z" id="H"/>
            <path className="st0" d="M101.56 173.61c-29.67-5.04-85.66-61.03-63.83-104.14s80.62-77.26 117.58-50.39 77.26 69.43 43.67 109.74-67.75 49.83-97.42 44.79z" id="G"/>
            <path className="st0" d="M71.66 185.97c-26.89-4.57-77.62-55.3-57.84-94.36S86.88 21.6 120.36 45.95s70.01 62.91 39.57 99.44-61.38 45.14-88.27 40.58z" id="F"/>
            <path className="st0" d="M96.56 188.26c-23.75-4.03-68.58-48.85-51.1-83.37S110 43.04 139.59 64.56s61.85 55.58 34.96 87.85-54.24 39.89-77.99 35.85z" id="E"/>
            <path className="st0" d="M99.48 150.1c-19.08-3.24-55.09-39.25-41.05-66.97s51.85-49.69 75.62-32.41 49.69 44.65 28.09 70.58-43.57 32.04-62.66 28.8z" id="D"/>
            <path className="st0" d="M88.66 137.83c-14.38-2.44-41.52-29.58-30.94-50.48S96.8 49.9 114.71 62.92s37.45 33.65 21.17 53.19-32.84 24.16-47.22 21.72z" id="C"/>
            <path className="st0" d="M104.29 126.27c-10.18-1.9-29.67-21.38-22.45-36.01s27.14-25.98 39.95-16.56 26.84 24.21 15.58 37.81-22.89 16.65-33.08 14.76z" id="B"/>
            <path className="st0" d="M106.52 120.79c-5.49-.3-16.82-9.29-13.76-17.57s13.02-15.71 20.29-11.6c7.27 4.11 15.48 10.99 10.22 19.01s-11.26 10.46-16.75 10.16z" id="A"/>
          </svg>
        </div>
      </div>
    </header>
  )
}

export default Hero
