import React from "react"
import { FaNewspaper, FaCalendarWeek, FaBusinessTime } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaNewspaper className="service-icon" />,
    title: "News",
    text: `News stories and the latest updates from Lancaster and the surrounding area related to technology, internet, code, hardware, and more. If you would like to submit a news item please get in touch.`,
  },
  {
    id: 2,
    icon: <FaCalendarWeek className="service-icon" />,
    title: "Events",
    text: `Meetups and events from Lancaster's tech community. Beer likely, avocados not guaranteed.`
  },
  {
    id: 3,
    icon: <FaBusinessTime className="service-icon" />,
    title: "Jobs",
    text: `Looking for new opportunities in the technology sector in and around Lancaster? Look no further, we list the latest career opportunities with Lancaster's leading digital companies and put you in touch with them directly.`,
  },
]
